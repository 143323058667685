import { Modal, Notification } from '@contentful/f36-components';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import TransferMethodDropIn from './TransferMethodDropIn';
import TransferMethodForm from 'src/components/Hyperwallet/TransferMethodForm';
import useParseHwData from 'src/hooks/hyperwallet/useParseHwData';
import useHwEnv from 'src/hooks/useHwEnv';
import { useMutateCharitySettings, useCurrentTransferMethod } from 'src/hooks';

export interface TransferMethodTypes {
  profileType: string;
  type: string;
}

interface TransferMethodModalProps {
  open: boolean;
  onClose: any;
  authData: any;
}

export const TransferMethodModal: React.FunctionComponent<
  TransferMethodModalProps
> = ({ open, onClose, authData }: TransferMethodModalProps) => {
  const queryClient = useQueryClient();
  const { reqBody, setReqBody } = useParseHwData();
  const { setCurrentTransferMethod } = useCurrentTransferMethod();
  const { mutate: mutateCharitySettings } = useMutateCharitySettings();
  const [hwTypesForConfig, setHwTypesForConfig] = useState({
    type: '',
    profileType: 'BUSINESS',
  });
  const environment = useHwEnv();
  const [step, setStep] = useState(0);

  const doComplete = (trmObject: any, completionResult: any) => {
    mutateCharitySettings(trmObject);
    setCurrentTransferMethod(trmObject);

    Notification.success(completionResult);
  };

  const handleStepChange = (values: TransferMethodTypes) => {
    setStep(1);
    setHwTypesForConfig({ type: values.type, profileType: values.profileType });
    setReqBody({
      ...reqBody,
      type: values.type,
      profileType: values.profileType,
    });
  };

  const handleModalClose = () => {
    onClose();
    setStep(0);
  };
  return (
    <Modal onClose={handleModalClose} isShown={open}>
      <div className="flex flex-row justify-end space-x-3">
        <button
          className=" text-primary hover:text-blue-400"
          onClick={() => handleModalClose()}
        >
          Cancel
        </button>
        {step === 1 && (
          <button
            className=" text-primary hover:text-blue-400"
            onClick={() => setStep(0)}
          >
            Reset
          </button>
        )}
      </div>

      {step === 0 && <TransferMethodForm onStepChange={handleStepChange} />}

      {step === 1 && (
        <TransferMethodDropIn
          complianceData={reqBody}
          configurationTypes={hwTypesForConfig}
          environment={environment}
          userToken={authData?.userToken}
          getAuthenticationToken={(callback: any) => {
            callback(authData?.authToken);
          }}
          onComplete={async (trmObject: any, completionResult: any) => {
            onClose();
            setStep(0);
            doComplete(trmObject, completionResult);
            await queryClient.invalidateQueries(['authToken']);
          }}
          onError={(e) => {
            console.log('Error!', e);
          }}
        />
      )}
    </Modal>
  );
};

export default TransferMethodModal;
