import React, { useCallback, useEffect, useState } from 'react';
import DashboardHeader from '../common/DashboardHeader';
// import AccountOwner, { TeamMember } from "./AccountOwner";
import AccountOwner from './AccountOwner';
// import Admins from "./Admins";
// import Clerks from "./Clerks";
import NavButton from '../common/NavButton';
import { getUsersInTeam } from 'src/api/frontend/getUsers';

type MyTeamProps = {};

const MyTeam: React.FunctionComponent<MyTeamProps> = ({ children }) => {
  const [selectedView, setSelectedView] = useState('owner');
  const [teamMembers, setTeamMembers] = useState([]);

  const setTeamResponse = useCallback((response) => {
    setTeamMembers(response);
  }, []);

  const getTeamUsers = useCallback(async () => {
    const response = await getUsersInTeam();
    setTeamResponse(response);
  }, [setTeamResponse]);

  useEffect(() => {
    getTeamUsers();
  }, [getTeamUsers]);

  return (
    <div className="min-h-screen overflow-hidden bg-background font-poppins">
      <DashboardHeader currentPage="team" />
      <div className="flex flex-row space-x-8 p-8 desktop:flex-row phone:flex-col">
        <div id="team-sidebar">
          <h2 className="font-medium text-xxl text-homeTextColor">My Team</h2>
          <br />
          <div>
            <NavButton
              text="Users"
              onClick={() => setSelectedView('owner')}
              selected={selectedView === 'owner'}
            />
            <br />
            {/* <NavButton
              text='Admins'
              onClick={() => setSelectedView('admins')}
              selected={selectedView === 'admins'}
            />
            <br />
            <NavButton
              text='Clerks'
              onClick={() => setSelectedView('clerks')}
              selected={selectedView === 'clerks'}
            /> */}
          </div>
        </div>
        <div id="team-content" className="w-full">
          {selectedView === 'owner' ? (
            <AccountOwner data={teamMembers} />
          ) : null}
          {/* {selectedView === "admins" ? <Admins /> : null}
          {selectedView === "clerks" ? <Clerks /> : null} */}
        </div>
      </div>
    </div>
  );
};

export default MyTeam;
