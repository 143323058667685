import React, { useEffect, useState } from 'react';
import DashboardHeader from '../common/DashboardHeader';
import DashboardPaymentSettings from './DashboardPaymentSettings';
import Banner from '../common/Banner';
import BannerBody from './BannerBody';
import TransferMethodModal from '../Hyperwallet/TransferMethodModal';
import { useAuthToken, useLogout } from 'src/hooks';
import Sidebar from './Sidebar';

type CharityDashboardProps = {};

const CharityDashboard: React.FunctionComponent<CharityDashboardProps> = () => {
  const [selectedView, setSelectedView] = useState('home');
  const [showBanner, setShowBanner] = useState(false);
  const [paymentMethodType, setPaymentMethodType] = useState('');
  const [showTransferMethod, setShowTransferMethod] = useState(false);
  const { data: authData, status: authDataStatus } = useAuthToken();
  const { mutate: logout } = useLogout();

  useEffect(() => {
    setPaymentMethodType(authData?.type as string);
    return () => {
      if (authDataStatus === 'success')
        setShowBanner(!authData?.transferMethods);
    };
  }, [authData, authDataStatus]);

  const openTransferModal = (bool: boolean) => (e: any) => {
    setShowTransferMethod(bool);
  };
  const handleLogout = (e: any) => {
    e.preventDefault();
    logout();
  };

  return (
    <div className="min-h-screen overflow-hidden text-gray-600 bg-background font-poppins">
      <DashboardHeader currentPage="dashboard" onLogout={handleLogout} />

      <div className="flex desktop:flex-row phone:flex-col desktop:p-8 phone:p-4">
        <Sidebar
          setSelectedView={setSelectedView}
          selectedView={selectedView}
        />

        <div
          id="dashboard-content"
          className="flex flex-col w-4/5 mx-auto gap-y-12"
        >
          {showBanner && (
            <Banner
              actionText="Link a Transfer Method"
              onActionClick={openTransferModal(true)}
              actionClickDisabled={authData?.authToken === ''}
              className="flex flex-col"
            >
              <BannerBody />
            </Banner>
          )}

          <DashboardPaymentSettings
            openTransferModal={openTransferModal(true)}
            paymentMethodType={paymentMethodType as string}
          />
        </div>
      </div>

      <div className="flex flex-col">
        <TransferMethodModal
          open={showTransferMethod}
          onClose={openTransferModal(false)}
          authData={authData as any}
        />
      </div>
    </div>
  );
};

export default CharityDashboard;
