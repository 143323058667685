import React, { MouseEventHandler, useState } from 'react';
import classnames from 'classnames';
import { XCircleIcon } from '@heroicons/react/outline';

interface BannerProps {
  children: React.ReactNode;
  className: string;
  onActionClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  actionText?: string;
  actionClickDisabled?: boolean;
}

const Banner: React.FunctionComponent<BannerProps> = ({
  children,
  className,
  onActionClick,
  actionText,
  actionClickDisabled,
}) => {
  const [hidden, setHidden] = useState(false);
  return (
    // TODO: convert this to a grid. it would work much better that way
    <div
      className={classnames(
        'flex shadow-banner rounded-lg pt-6 pl-6 pb-7 pr-6 bg-white ',
        hidden ? 'hidden' : '',
        actionText ? 'h-72 phone:h-400' : 'desktop:h-56 phone:h-72'
      )}
    >
      <div className={classnames(className, 'w-4/5')}>{children}</div>
      <div className="w-1/5 flex phone:flex-col h-full relative">
        <div className="absolute top-0 right-0">
          <div className="flex flex-row">
            <button
              className="flex flex-row space-x-2"
              onClick={() => setHidden(true)}
            >
              <span className="text-primary font-medium">Dismiss</span>
              <XCircleIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
        {actionText && (
          <div className="absolute bottom-0 right-0 shadow-div mb-4">
            <button
              className="bg-primary text-white w-60 p-3 rounded-md text-sm font-medium disabled:opacity-75"
              type="submit"
              onClick={onActionClick}
              disabled={actionClickDisabled}
            >
              {actionText}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Banner;
