import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Heading } from '@contentful/f36-components';
import * as Yup from 'yup';
import { Select } from '../common/FormComponents';

const TransferMethodFormSchema = Yup.object().shape({
  profileType: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
});

interface TransferMethodFormProps {
  onStepChange: any;
}

export const TransferMethodForm: FC<TransferMethodFormProps> = ({
  onStepChange,
}) => {
  const doSubmit = (values: any) => {
    if (values.profileType && values.type) onStepChange(values);
  };

  return (
    <Formik
      onSubmit={doSubmit}
      initialValues={{ profileType: 'BUSINESS', type: '' }}
      validationSchema={TransferMethodFormSchema}
    >
      {() => (
        <Form className="p-4">
          <Heading>Account Types</Heading>

          {/* <div className="flex flex-row space-x-3">
            <Select
              name="profileType"
              text="Profile Type"
              handleChange={doSubmit}
              width="w-full"
              options={[
                { text: 'Individual', value: 'INDIVIDUAL' },
                { text: 'Business', value: 'BUSINESS' },
              ]}
            />
          </div> */}
          <div className="flex flex-row space-x-3">
            <Select
              name="type"
              text="Type"
              handleChange={doSubmit}
              width="w-full"
              options={[
                { text: 'Bank Account', value: 'BANK_ACCOUNT' },
                { text: 'PayPal Account', value: 'PAYPAL_ACCOUNT' },
              ]}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default TransferMethodForm;
