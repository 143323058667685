import { Modal } from '@contentful/f36-components';
import CharityInfoForm from './CharityInfoForm';
import { Charity } from './MyCharity';

interface EditCharityModalProps {
  show: boolean;
  setShow: any;
  charity: Charity;
  setCharity: any;
}

export default function EditCharityModal({
  show,
  setShow,
  charity,
  setCharity,
}: EditCharityModalProps) {
  return (
    <div>
      <Modal
        onClose={() => setShow(false)}
        shouldCloseOnOverlayClick
        shouldCloseOnEscapePress
        modalHeaderProps={{ className: 'text-white' }}
        isShown={show}
      >
        {() => (
          <>
            {/* <Modal.Header
              title={(<span className="text-white ">Edit Charity</span>) as any}
              className="bg-primary text-white"
              onClose={() => setShow(false)}
            /> */}
            <div
              className="bg-primary py-5 
            flex flex-row items-center pl-6 text-white"
            >
              Edit Charity Info
            </div>
            <Modal.Content>
              <CharityInfoForm
                charity={charity}
                setCharity={setCharity}
                setShow={setShow}
              />
            </Modal.Content>
          </>
        )}
      </Modal>
    </div>
  );
}
