import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { loginCharity } from 'src/api/frontend/loginCharity';

const loginCharityData = async (payload: any, history: any) => {
  const loginRes = await loginCharity(payload);

  history.push('/');

  return loginRes;
};

export function useLoginCharity() {
  const history = useHistory();

  return useMutation((payload: { email: string; password: any }) =>
    loginCharityData(payload, history)
  );
}

export default useLoginCharity;
