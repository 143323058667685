import { useQuery } from 'react-query';
import httpService from 'src/services/src/lib/http-service';

const getCharitySettings = async () => {
  const { data } = await httpService.get(
    `.netlify/functions/current-charity-settings`
  );
  return data.response;
};

export function useCharitySettings() {
  return useQuery('charitySettings', () => getCharitySettings());
}

export default useCharitySettings;
