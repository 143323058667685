import { useQuery } from 'react-query';
import { getHwUser } from 'src/api/frontend/getHwUser';
import { useAuthToken } from '.';

const getUser: any = async (userToken: string) => {
  return await getHwUser(userToken);
};

export function useUser() {
  const { data, isSuccess } = useAuthToken();

  return useQuery('hyperWalletUser', () => getUser(data?.userToken as string), {
    enabled: isSuccess,
  });
}

export default useUser;
