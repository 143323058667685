export function useCookies() {
  const eraseCookie = (name: string) => {
    document.cookie = name + '=; Max-Age=-99999999;';
  };

  const getCookie = (name: string) => {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];

      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  return { eraseCookie, getCookie };
}

export default useCookies;
