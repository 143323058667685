import React from 'react';
import NavButton from '../common/NavButton';

interface SidebarProps {
  selectedView: string;
  setSelectedView: Function;
}

export function Sidebar({ setSelectedView, selectedView }: SidebarProps) {
  return (
    <div id="dashboard-sidebar" className="flex flex-col space-y-4 w-72">
      <h2 className="font-medium text-xxl text-homeTextColor">Home</h2>
      <div className="mt-12">
        <NavButton
          text="Overview"
          onClick={() => setSelectedView('home')}
          selected={selectedView === 'home'}
        />
        <br />
      </div>
    </div>
  );
}

export default Sidebar;
