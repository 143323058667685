// import classNames from "classnames";
import React, { useMemo } from 'react';
import Table from '../common/Table';
// import AddAUser from "./AddAUser";
import { UserAddIcon } from '@heroicons/react/solid';

export type TeamMember = {
  email: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  role: {
    id: number;
    name: string;
  };
};

type AccountOwnerProps = {
  data: Array<TeamMember>;
};

const formatData = (data: TeamMember) => {
  console.log(data);
  return {
    ...data,
    name: data.firstName + ' ' + data.lastName,
  };
};

const AccountOwner: React.FunctionComponent<AccountOwnerProps> = ({
  children,
  data,
}) => {
  // TODO: have an empty sort of state or something
  // TODO: we need to show blurb about adding employees and coworkers
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Job Title',
        accessor: 'jobTitle',
      },
      {
        Header: 'Role',
        accessor: 'role.name',
      },
    ],
    []
  );

  // const [viewAddUserForm, setViewAddUserForm] = useState<boolean>(false);
  // const [userValues, setUserValues] = useState<Record<string, string>>({});
  // const [hasValues, setHasValues] = useState<boolean>(false);

  // const addUser = (userValues: Record<string, string>) => {
  //   const { first, last, email, clerk, admin } = userValues;
  //   const name = `${first} ${last}`;
  //   const status = clerk || admin ? "Inactive" : "Active";
  //   const userObject = {
  //     name,
  //     email,
  //     status,
  //   };

  //   // data.push(userObject);
  //   // setData([...data, userObject]);
  // };
  return (
    <div className="flex desktop:flex-row phone:flex-col w-full desktop:space-x-1 phone:space-y-1">
      <div>
        <Table
          columns={columns}
          data={data.map((member: TeamMember) => {
            return formatData(member);
          })}
          search={false}
          pagination={false}
          details={false}
          onActionClick={(row: Record<string, string>) => {
            // setUserValues(row);
            // setViewAddUserForm(true);
            // setHasValues(true);
          }}
          img={false}
        />
        <div className="flex flex-col w-full text-center p-8 bg-white rounded-b-lg">
          <div className=" mx-auto w-1/4">
            <UserAddIcon className=" text-gray-400" />
          </div>
          <p className="text-sm mx-auto w-1/2 text-gray-500">
            Add your employees and coworkers here. “Admins” can see and edit
            everything and “Clerks” can only see information.{' '}
          </p>

          <p className="text-sm p-6 text-gray-500">
            The Account Holder can change their roles at any time.
          </p>
        </div>
      </div>
      {/* <div className="desktop:w-2/5 phone:w-full flex flex-col text-center items-center">
        <button
          className={classNames(
            "bg-primary text-white w-56 p-3 rounded-md text-base font-medium",
            viewAddUserForm ? "hidden" : ""
          )}
          onClick={() => {
            setViewAddUserForm(true);
          }}
        >
          Add A User
        </button>
        {viewAddUserForm && (
          <AddAUser
            values={userValues}
            onCancel={() => {
              setViewAddUserForm(false);
              setHasValues(false);
            }}
            onInvite={(userValues: Record<string, string>) => {
              setViewAddUserForm(false);
              setHasValues(false);
              // addUser(userValues);
            }}
            classNames={classNames(!viewAddUserForm ? "hidden" : "")}
            hasValues={hasValues}
          />
        )}
      </div> */}
    </div>
  );
};

export default AccountOwner;
