import React from "react";
import GivingaLogo from "../common/GivingaLogo";
import LoginHeader from "./LoginHeader";

type ResetPasswordProps = {};

// TODO: add reset password action

const ResetPassword: React.FunctionComponent<ResetPasswordProps> = ({
  children,
}) => {
  const handleSubmit = (event: any) => {
    event.preventDefault();
  };
  return (
    <div className="flex flex-col min-h-screen overflow-hidden bg-background font-poppins">
      <LoginHeader />
      <div className="flex items-center justify-center object-contain h-auto m-auto w-full mt-16 mb-8">
        <GivingaLogo login />
      </div>
      <form onSubmit={handleSubmit}>
        <div
          id="reset-form"
          className="m-auto shadow-md bg-white w-400 rounded-md -mt-4"
        >
          <p className="text-lg bg-primary w-full text-white rounded-md p-4 pl-8 font-medium">
            Reset Password
          </p>
          <div className="p-4">
            <div className="flex flex-col p-4">
              <p className="text-einText">
                Enter the email associated with your account. We'll send a reset
                link.
              </p>
              <p className="text-sm mt-6 pb-1">Email</p>
              <input
                name="email"
                type="email"
                className="w-full h-10 text-loginText placeholder-gray-400 border h-8 p-1 border-gray-300 text-gray-600 focus:outline-none"
                placeholder="Enter your email#"
              />
            </div>
            <button
              className="bg-primary text-white w-full h-11 rounded-lg text-login mt-40 mb-4 font-medium hover:opacity-80"
              type="submit"
              onClick={() => alert("Click")}
            >
              Send Reset
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
