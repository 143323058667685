import { useQuery } from 'react-query';
import { getComplianceData } from 'src/api/frontend/getComplianceData';

const getCompliance = async () => {
  const response = await getComplianceData();
  const {
    compliance_data: { data: complianceData },
  } = response;

  return complianceData;
};

export function useComplianceData() {
  return useQuery('compliance data', () => getCompliance());
}

export default useComplianceData;
