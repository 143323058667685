import React, { FC } from 'react';

interface IProps {}

const BannerBody: FC<IProps> = (props) => {
  return (
    <div className="flex desktop:flex-row phone:flex-col">
      <div className="flex flex-col w-full">
        <div className="flex flex-col">
          <span className="font-medium text-gray-400 desktop:text-sm phone:text-xs">
            INTRODUCING
          </span>
          <span className="font-medium desktop:text-bannerLarge phone:text-lg_2 text-homeTextColor">
            Electronic Payments
          </span>
          <span className="text-xl font-medium desktop:text-dashBanner phone:text-base">
            No more paper checks, get paid fast, and know where your money is.
          </span>
        </div>
        <div className="flex desktop:flex-row phone:flex-col">
          <div className="flex flex-wrap space-y-4 text-sm desktop:flex-col text-dataTextColor w-60">
            <span className="mt-4">
              We’ve partnered with Hyperwallet to make electronic payments a
              snap.
            </span>
            <span className="">
              Set up a transfer method to get the funds flowing electronically.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerBody;
