import { useQuery } from 'react-query';
import { getCookieAuth } from 'src/api/frontend/getCookieAuth';

const getAuthCookie = async () => {
  const response = await getCookieAuth();
  const { data } = response;

  return data;
};

export function useAuthCookie(isDirty: boolean) {
  return useQuery('get-auth-cookie', () => getAuthCookie(), {
    enabled: isDirty,
  });
}

export default useAuthCookie;
