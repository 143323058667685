import React from "react";
import { useState, useEffect } from 'react';
import { getAttributionLetter } from '../../api/frontend/getAttributionLetter';
import { RouteComponentProps } from "react-router-dom";
import treeIcon from "../../img/tree_icon.svg";
import givingaBlueLogo from "../../img/givinga_logo.png";
import Spinner from '../common/Spinner';

const reactToPdf = () => {
  window.print();
};

interface Charity {
  ein: string;
  id: number;
}

interface Donation {
  amount: number;
  date: number;
  notes: string;
  anonymity: string;
}

interface Matched {
  amount: number;
}

interface Donor {
  name: string;
  email: string;
  address: Address;
}

interface Address {
  id: number;
  parentId: number;
  address: string;
  address2: string;
  city: string;
  zip: string;
  phone: string;
}

interface Corporation {
  name: string;
  logo: string;
  statement: string;
}

interface DonationAttribution {
  donation: Donation;
  isMatched: boolean;
  matched: Matched;
  donor: Donor;
  charity: Charity;
  corporation: Corporation;
}


const AttributionLetter = ({ match }: RouteComponentProps<{ transactionId?: string}>) => {
  const id = match.params?.transactionId;

  const [loading, setLoading] = useState(false);
  const [attribution, setAttribution] = useState<DonationAttribution | undefined>(undefined);


  useEffect(() => {
    if (attribution === undefined) {
      (async () => {
        setLoading(true);
        const { error, attribution } = await getAttributionLetter(id ?? "");
        if (!error && attribution) setAttribution(attribution.data);
        setLoading(false);
      })();
    }
  }, [id, attribution]);


  return (
    <div>
      {
        loading ? (
          <div className="flex h-screen justify-center items-center">
            <Spinner />
          </div>
        ) : (
          attribution ? (
          <div className="flex flex-col min-h-screen overflow-hidden bg-background font-poppins items-center">
            <div className="flex-row justify-center object-contain h-auto m-auto w-full mt-16 mb-8 py-8 items-center max-w-4xl bg-white shadow-lg space-y-12 px-8">
              <div className="flex flex-row space-x-2 items-end">
                <img className="h-16" alt="Givinga Logo" src={givingaBlueLogo}></img>
              </div>
              <div className="text-green-900 text-xxl text-center"><b>Congratulations!</b></div>
              <div className="">The Givinga Foundation a 501c3 sponsoring organization with the <b>EIN 47-4172718</b> is sending your organization a GRANT from one of our Donor Advised Funds. Details on this grant are provided below. Please note that we provide all information that we have related to this grant in this notice.</div>
              <div className="bg-green-600 bg-opacity-20 p-6 rounded-xl space-y-4 align-middle">
                <div className="flex flex-row space-x-2 align-middle">
                  <div className="underline"><b>Save the Environment!!</b></div>
                  <img src={treeIcon} alt="Tree Icon"></img>
                </div>
                <div>Since we are a Donor Advised Fund, there is no need to provide us with a tax receipt or thank you letter. You will not hurt our feelings. We also kindly ask that you <b>do not add the Givinga Foundation to your mailing list or send any unsolicited mailings.</b></div>
              </div>
              <div className="py-8">All Grantor details are located below. Per the request of the donor, <div className="underline inline"><b>this notice contains all the information we have been provided by the donor.</b></div></div>

            { attribution.corporation.logo ? (
              <div className="flex flex-col items-center">
                <div className="max-w-md mb-8">
                  <img src={attribution.corporation.logo} alt="Corporation Logo"></img>
                </div>
                {attribution.corporation.statement}
              </div>
              ) : (<></>)
            }


          <div className="flex flex-col">
            { 
                attribution.isMatched ? (
                    <div className="rounded-full bg-green-900 mb-4">
                      <div className="text-md px-4 text-white text-center">
                        Matched Donation from {attribution.corporation.name}
                      </div>
                    </div>
                ) : (
                  <></>
                )
            }
            { 
              attribution.donor.name ? (
                  <div className="flex flex-col">
                  <div className="text-md underline max-h-100">Donation Details:</div>
                  <div className="">ID: {id}</div>
                  <div className="">Donor Name: {attribution.donor.name}</div>
                  <div className="">Donor Email: {attribution.donor.email}</div>
                  {
                    (attribution.donor.address) ? (
                        <div className="">Donor Address: {attribution.donor.address.address} {attribution.donor.address.address2} {attribution.donor.address.city} {attribution.donor.address.zip}</div>
                    )
                    : (<></>)
                  } 
                  <div className="">Donor Notes: {attribution.donation.notes}</div>
                </div>
                ) : (
                  <div className="py-8"><b>Donation made anonymously</b></div>
                )
            }
          </div>


              <div className="flex flex-row flex-items-stretch align-baseline">
                <div className="flex flex-col w-1/2">
                  <div className="text-md underline">Still have questions?</div>
                  <div className="">Contact support@givingafoundation.org</div>
                </div>


                <div className="flex flex-row space-x-2 w-1/2 justify-end">
                    <button className="rounded-full" onClick={() => reactToPdf()}><b>Download PDF</b></button>
                </div>
              </div>

              <div className="flex flex-row items-stretch text-xs text-center">
                <div className="w-1/4">GIVINGA FOUNDATION</div>
                <div className="w-1/4">396 WASHINGTON ST. SUITE 307</div>
                <div className="w-1/4">WELLESLEY, MA 02481</div>
                <div className="w-1/4">(339) 200-9064</div>
              </div>

            </div>
          </div>
          ) : (
            <div className="flex h-screen justify-center items-center">
              <p>No Attribution Data</p>
            </div>
          )
        )
      }
    </div>
  );
};

export default AttributionLetter;
