import React, { useEffect } from 'react';
import { FormControl, Select as FormaSelect } from '@contentful/f36-components';
import { Field, useFormikContext } from 'formik';

export interface Option {
  value: string;
  text: string;
}

export interface SelectProps {
  name: string;
  text: string;
  className?: string;
  handleChange: any;
  width?: string;
  options: Option[];
}

export const Select = ({
  options,
  name,
  text,
  className = '',
  handleChange,
  width = '',
}: SelectProps) => {
  const { submitForm, values } = useFormikContext();
  useEffect(() => {
    handleChange(values, submitForm);
  }, [handleChange, submitForm, values]);

  return (
    <div className={width || ''}>
      <Field name={name} style={{ appearance: 'none' }}>
        {({ field, meta }: { field: any; meta: any }) => {
          return (
            // To set the FormControl invalid state, check if there is an error related to this Select
            <FormControl
              onChange={handleChange}
              isInvalid={Boolean(meta.touched && meta.error)}
            >
              <FormControl.Label>{text}</FormControl.Label>
              <FormaSelect className="formaSelect" {...field}>
                <FormaSelect.Option key={`empty-${name}-option`} value="">
                  Please Select
                </FormaSelect.Option>
                {options.map((option) => (
                  <FormaSelect.Option key={option.value} value={option.value}>
                    {option?.text}
                  </FormaSelect.Option>
                ))}
              </FormaSelect>

              {meta.touched && meta.error && (
                <FormControl.ValidationMessage>
                  {meta.error}
                </FormControl.ValidationMessage>
              )}
            </FormControl>
          );
        }}
      </Field>
    </div>
  );
};

export default Select;
