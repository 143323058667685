import axios from '../../utils/axios';

type Response = {
  data: {
    auth: {
      Authorization: string;
    };
  };
};

export const logout = async () => {
  const data: Response = await axios
    .post(
      `/.netlify/functions/logout`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then((r: any) => r)
    .catch((e: any) => e);
  return data;
};

export default logout;
