import React from 'react';
import crisp from '../../img/Group@2x.png';
import newLogo from '../../img/logomaingiv.png';
import classNames from 'classnames';

interface GivingaLogoProps {
  login?: boolean;
}

const GivingaLogo: React.FunctionComponent<GivingaLogoProps> = ({
  login = false,
}) => {
  return (
    <div
      className={classNames(
        'flex flex-row',
        login ? 'items-center justify-center' : ''
      )}
    >
      <img
        src={login ? newLogo : crisp}
        alt="Givinga Charity Portal Logo"
        className={classNames(
          'mr-2 object-contain desktop:h-1/2 phone:h-1/2 tablet:h-1/2 desktop:w-1/3 tablet:w-1/2',
          login ? '' : 'm-0'
        )}
      />

      {
        <div
          className={classNames(
            'flex relative border-l ml-2 w-36 items-center ',
            login ? 'border-gray-400' : 'border-white'
          )}
        >
          <div
            className={classNames(
              login ? 'text-black' : 'text-white',
              // there's a bug with the phone hidden. will come back to it later
              'ml-3 desktop:w-full tablet:w-1/2 desktop:display'
            )}
          >
            Charity Portal
          </div>
        </div>
      }
    </div>
  );
};

export default GivingaLogo;
