import React, { useState } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
// import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/entry.nostyle";

const CommonDateRange = (props) => {
  const [value, onChange] = useState([new Date(), new Date()]);

  return (
    <div className="border-none">
      <DateRangePicker
        onChange={onChange}
        value={value}
        calendarAriaLabel={"Toggle Calendar"}
        rangeDivider={"→"}
      />
    </div>
  );
};

export default CommonDateRange;
