import { FormControl, Textarea } from '@contentful/f36-components';
import { Field } from 'formik';

export interface TextAreaProps {
  name: String;
  text: String;
  className: String;
}

export const TextArea = ({ name, text, className }: TextAreaProps) => {
  return (
    <Field name={name}>
      {({ field, meta }: { field: any; meta: any }) => (
        <FormControl isInvalid={Boolean(meta.touched && meta.error)}>
          <FormControl.Label>{text}</FormControl.Label>
          <Textarea {...field} />

          {meta.touched && meta.error && (
            <FormControl.ValidationMessage>
              {meta.error}
            </FormControl.ValidationMessage>
          )}
        </FormControl>
      )}
    </Field>
  );
};

export default TextArea;
