import axios from "../../utils/axios";

export const getComplianceData = async () => {
  const { error, data } = await axios
    .get(`/.netlify/functions/get-compliance`)
    .then((r: any) => r)
    .catch((e: any) => e);

  return { error, compliance_data: data };
};
