import axios from '../../utils/axios';

export const getTransactions = async () => {
  const { error, data } = await axios
    .get(`/.netlify/functions/get-transactions`)
    .then((r: any) => r)
    .catch((e: any) => e);

  return { error, transactions: data };
};
