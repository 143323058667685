export const ClaimCharitySuccess = () => (
  <div
    id="thank-you"
    className="m-auto -mt-4 bg-white border rounded-md shadow-login desktop:w-600 phone:w-72 h-54"
  >
    <div className="w-full pt-5 font-bold text-white rounded-t-lg bg-primary pl-7 pb-7">
      <span className="text-loginHeader">Thank you</span>
    </div>
    <div className="p-4">
      <div className="flex flex-col p-4 space-y-5">
        <span className="font-bold">Registration successful!</span>
        <span>
          We'll verify your credentials and be in touch soon through the email
          you provided.
        </span>
        <span>
          <a
            href="mailto:info@givinga.com"
            className="underline text-primary underline-offset-small"
          >
            Reach out here
          </a>
          &nbsp; with any questions.
        </span>
      </div>
    </div>
  </div>
);

export default ClaimCharitySuccess;
