import React from 'react';

const LoginHeader: React.FunctionComponent = () => {
  return (
    <nav className="bg-primary h-24">
      <div className="w-full mx-auto px-4">
        <div className="relative flex items-center float-right justify-between"></div>
      </div>
    </nav>
  );
};

export default LoginHeader;
