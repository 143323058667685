import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import GivingaLogo from '../common/GivingaLogo';
import LoginHeader from './LoginHeader';
import { useLoginCharity } from 'src/hooks';

type CharityLoginProps = {
  children?: React.ReactNode;
};

const CharityLogin: React.FunctionComponent<CharityLoginProps> = ({
  children,
}) => {
  const {
    mutate: login,
    status,
    error,
  }: { mutate: Function; status: string; error: any } = useLoginCharity();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!isDirty) {
      login({
        email: event.target.email.value,
        password: event.target.password.value,
      });
    }
  };

  useEffect(() => {
    if (error && error.response.data.error.message === 'Invalid auth')
      setErrorMessage('Something went wrong. Please try again');
    else if (error) setErrorMessage(error.response.data.error.message);
  }, [error]);

  useEffect(() => {
    return () => {
      setIsDirty(true);
    };
  }, [isDirty]);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden bg-white font-poppins">
      <LoginHeader />
      <div className="flex items-center justify-center object-contain w-full h-auto m-auto mt-16 mb-8">
        <GivingaLogo login />
      </div>
      <form onSubmit={handleSubmit}>
        <div
          id="login-form"
          className="flex flex-col m-auto -mt-4 bg-white rounded-md shadow-login desktop:w-400 phone:w-72"
        >
          <p className="w-full pt-5 font-semibold text-white rounded-t-lg text-loginHeader bg-primary pl-7 pb-7">
            Login
          </p>
          <div className="p-4">
            <div className="flex flex-col p-4">
              <p className="pb-1 text-sm">Email</p>
              <input
                name="email"
                type="email"
                autoComplete="email"
                className="w-full h-10 text-gray-600 placeholder-gray-300 border-gray-300 text-loginText focus:outline-none"
                placeholder="Enter email"
              />
              <p className="pb-1 mt-6 text-sm">Password</p>
              <input
                name="password"
                type="password"
                autoComplete="current-password"
                className="w-full h-10 text-gray-600 placeholder-gray-300 border-gray-300 text-loginText focus:outline-none"
                placeholder="Enter password"
              />
              {status === 'error' && (
                <div className="mt-2 text-center text-red-400">
                  {errorMessage}.
                </div>
              )}
            </div>
            <div className="flex flex-col items-center mt-5">
              <p className=" font-semibold cursor-pointer text-primary text-loginText phone:text-center">
                <Link to="/claim">No Account? Register Your Charity Now</Link>
              </p>
            </div>

            <button
              className="w-full mt-10 mb-4 font-medium text-white rounded-lg bg-primary h-11 text-login hover:opacity-80"
              type="submit"
              disabled={status === 'loading'}
            >
              {status === 'loading' ? 'Submitting' : 'Submit'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CharityLogin;
