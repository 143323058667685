import axios from '../../utils/axios';

type Response = {
  config: Record<string, string>;
  data: {
    error: Record<string, unknown>;
    response: {
      type: string;
      userToken: string;
      authToken: string;
    };
  };
};

export const getAuthToken = async () => {
  const data: Response = await axios
    .get(`/.netlify/functions/get-auth-token`)
    .then((r: any) => r)
    .catch((e: any) => e);
  try {
    if (data.data.error !== null) {
      return data?.data?.error;
    }
  } catch (e) {
    return {
      message: 'Invalid Givinga Auth Token',
    };
  }

  return data.data.response;
};
