import { Formik } from 'formik';
import { Button, Flex, Notification, Form } from '@contentful/f36-components';
import { TextArea, TagInput, Input } from '../common/FormComponents';
import { Charity } from './MyCharity';
import * as Yup from 'yup';

const CharityFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Required'),
  ein: Yup.string()
    .min(8, 'Too Short!')
    .max(20, 'Too Long!')
    .required('Required'),
  location: Yup.string().required('Required'),
  founded: Yup.number().required('Required'),
  mission: Yup.string().min(5, 'Too Short!').required('Required'),
  description: Yup.string().min(5, 'Too Short!').required('Required'),
});

interface CharityInfoFormProps {
  charity: Charity;
  setCharity: any;
  setShow: any;
}

export const CharityInfoForm = ({
  setShow,
  setCharity,
  charity,
}: CharityInfoFormProps) => {
  const { tags } = charity;

  const initialValues = {
    ...charity,
  };

  const parseData = (data: any) => {
    let dataClone = { ...data };
    if (typeof data.tags === 'string') {
      let tagsArray = dataClone.tags.split(',');
      dataClone.tags = tagsArray;
    }
    return dataClone;
  };

  const onSubmit = (data: any) => {
    const parsedData = parseData(data);
    setShow(false);

    setCharity(parsedData);
    Notification.success('Success');
  };

  const handleTagChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    handleChange: any
  ) => {
    const charityClone = { ...charity };
    handleChange(e);
    let tagsArray = e.target.value.split(',');

    if (typeof tagsArray === 'string') tagsArray = [tagsArray];
    charityClone.tags = tagsArray;
    setCharity(charityClone);
  };

  const removeTag = (tag: string, setValues: any, values: any) => {
    const charityClone = { ...values };
    const newTags = tags.filter((t) => t !== tag);

    charityClone.tags = newTags;
    setCharity(charityClone);
    setValues(charityClone);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={CharityFormSchema}
    >
      {({ handleSubmit, handleChange, setValues, values }) => (
        <Form onSubmit={handleSubmit}>
          <div className="flex flex-row space-x-3">
            <Input name="name" text="Name" className="w-full" />
          </div>
          <div className="flex flex-row space-x-3"></div>
          <div className="flex flex-row space-x-3">
            <Input name="ein" text="EIN#" className="w-1/2" />
            <Input name="location" text="Location" className="w-1/2" />
            <Input
              name="founded"
              text="Founded"
              type="number"
              className="w-1/2"
            />
          </div>
          <TagInput
            name="tags"
            text="Tags"
            tags={tags}
            removeTag={removeTag}
            setValues={setValues}
            values={values}
            onChangeTags={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleTagChange(e, handleChange)
            }
          />
          <TextArea name="mission" text="Mission Statement" className="w-1/2" />
          <TextArea name="tagLine" text="Tag Line" className="w-1/2" />
          <Flex justifyContent="flex-end">
            <Button
              className="text-primary"
              variant="transparent"
              onClick={() => setShow(false)}
            >
              Cancel
            </Button>
            <Button
              className="bg-blue-900 ml-3"
              variant="primary"
              type="submit"
            >
              Save
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default CharityInfoForm;
