import axios from '../../utils/axios';

export const flipAccountToHyperwallet = async (updatedData?: any) => {
  const data = await axios.put(
    '/.netlify/functions/flip-hyperwallet',
    updatedData
  );

  return data;
};
