import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  SearchIcon,
} from '@heroicons/react/outline';
import React, { useState } from 'react';
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  usePagination,
} from 'react-table';
import { Button, PageButton } from './Button';
import CommonDateRange from './CommonDateRange';
import DownloadCSVButton from './DownloadCSVButton';

// Searchfilter
function GlobalFilter({ globalFilter, setGlobalFilter }) {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="relative p-4 text-gray-400 desktop:w-3/5 phone:w-full focus-within:text-gray-900">
      <span className="absolute inset-y-0 left-0 flex items-center pl-6">
        <button
          type="submit"
          className="p-1 focus:outline-none focus:shadow-outline"
        >
          <SearchIcon className="w-5 h-5" />
        </button>
      </span>
      <input
        name="data-search"
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={'Search by Donor, Organization, or Email'}
        className="w-full py-2 pl-10 text-sm text-gray-600 placeholder-gray-400 border-gray-300 rounded-full focus:outline-none"
      />
    </div>
  );
}

const Table = ({
  columns,
  data,
  search,
  pagination,
  details,
  edit = false,
  img,
  onActionClick,
  tableTitle = '',
  paymentPeriod = false,
  downloadCSV = false,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy, usePagination);

  return (
    <div className="flex flex-col rounded-b-lg gap-x-2 shadow-div">
      <div className="flex border-b-2 desktop:flex-row phone:flex-col gap-x-4">
        {tableTitle && (
          <div className="pt-6 pb-8 pl-6 ">
            <span className="font-bold text-lg_2 text-homeTextColor bg-white ">
              {tableTitle}
            </span>
          </div>
        )}
        {search && (
          <div className="pt-1 pl-1 desktop:w-full">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </div>
        )}
        {paymentPeriod && (
          <div>
            <div className="flex flex-col pt-4 text-primary">
              <p className="text-sm">Donation Period</p>
              <CommonDateRange />
            </div>
          </div>
        )}
        {downloadCSV && (
          <div>
            <div className="flex pt-8 pb-5 pr-7">
              <DownloadCSVButton />
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden border-b border-gray-200">
              <table
                {...getTableProps()}
                className="min-w-full divide-y divide-gray-200"
              >
                <thead className="bg-white">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          scope="col"
                          className="px-6 py-3 text-sm font-medium tracking-wider text-left text-gray-500"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render('Header')}
                          {/* TODO: Add a sort direction indicator */}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' ▼'
                                : ' ▲'
                              : ''}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="text-base bg-white divide-y divide-gray-200 text-dataTextColor"
                >
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {/* {img && (
                          <div className="flex items-center justify-center w-16 h-16 m-4 bg-white border">
                            {/* logo link for charity should go here */}
                        {/* <PhotographIcon className="w-16 h-16 text-gray-300" />
                          </div>
                        )} */}
                        {/* TODO: Figure out how to render img for the first column */}
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="px-6 py-4 whitespace-nowrap"
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                        {/* TODO: onclick of edit, passes the row values to an onActionClick method */}
                        {edit && (
                          <td
                            className="px-6 py-4 underline cursor-pointer whitespace-nowrap text-purpleText"
                            onClick={(e) => onActionClick(row.values)}
                          >
                            Edit
                          </td>
                        )}
                        {/* TODO: onClick of details to reveal charity details */}
                        {details && (
                          <td
                            className="px-6 py-4 font-medium cursor-pointer whitespace-nowrap text-purpleText"
                            onClick={(e) => onActionClick(row.values)}
                          >
                            Details
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {pagination && (
        <div className="flex items-center justify-between py-3">
          <div className="flex justify-between flex-1 sm:hidden">
            <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
              Previous
            </Button>
            <Button onClick={() => nextPage()} disabled={!canNextPage}>
              Next
            </Button>
          </div>
          <div className="flex flex-row items-center justify-between w-full gap-x-4">
            <div className="flex items-center pl-4 gap-x-4">
              <span className="text-sm text-gray-700">
                Page <span className="font-medium">{state.pageIndex + 1}</span>{' '}
                of <span className="font-medium">{pageOptions.length}</span>
              </span>
              <select
                value={state.pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 20].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <nav
                className="relative z-0 inline-flex pr-4 -space-x-px rounded-md shadow-sm"
                aria-label="Pagination"
              >
                <PageButton
                  className="rounded-l-md"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  <span className="sr-only">First</span>
                  <ChevronDoubleLeftIcon
                    className="w-5 h-5"
                    aria-hidden="true"
                  />
                </PageButton>
                <PageButton
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
                </PageButton>
                <PageButton onClick={() => nextPage()} disabled={!canNextPage}>
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
                </PageButton>
                <PageButton
                  className="rounded-r-md"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  <span className="sr-only">Last</span>
                  <ChevronDoubleRightIcon
                    className="w-5 h-5"
                    aria-hidden="true"
                  />
                </PageButton>
              </nav>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Table;
