import { claimCharity } from './../../../api/frontend/claimCharity';
import { useMutation } from 'react-query';

export function useClaimCharity() {
  return useMutation({
    mutationFn: (claim: any) => claimCharity(claim),
    mutationKey: 'claimCharity',
  });
}

export default useClaimCharity;
