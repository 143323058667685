//import Honeybadger from 'honeybadger';

const init = () => {
  //Honeybadger.configure({
  //    apiKey: 'project api key',
  //    environment: 'production',
  //    revision: 'git SHA/project version'
  //});
};
const log = (error) => {
  console.error('log-service error', error);
  //Honeybadger.notify(error);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { init, log };
