import axios from "../../utils/axios";

type Response = {
  data: {
    auth: {
      Authorization: string;
    };
  };
};

export const getCookieAuth = async () => {
  const data: Response = await axios
    .get(`/.netlify/functions/get-auth-cookie`)
    .then((r: any) => r)
    .catch((e: any) => e);
  return data;
};
