import { useQuery } from 'react-query';
import { getUsersInTeam } from 'src/api/frontend/getUsers';

const getUsers = async () => {
  return await getUsersInTeam();
};

export function useUsers() {
  return useQuery('users', () => getUsers());
}

export default useUsers;
