import React, { useState } from 'react';
import DashboardHeader from '../common/DashboardHeader';
import MyCharityCampaign from './MyCharityCampaigns';
import MyCharityInformation from './MyCharityInformation';
import Banner from '../common/Banner';
import NavButton from '../common/NavButton';
import EditCharityModal from './EditCharityModal';
import { useCharity } from 'src/hooks';
import { useEffect } from 'react';

type MyCharityProps = {};
interface Address {
  city: String;
  state: String;
}
interface Category {
  iconUrl: string;
  displayName: string;
}
export interface Charity {
  name: String;
  address: Address | string;
  category: Category | string;
  generalEmail: String;
  ein: String;
  tags: string[];
  mission: String;
  tagLine: String;
}

// TODO: update Campagain screen

const MyCharity: React.FunctionComponent<MyCharityProps> = ({ children }) => {
  const [selectedView, setSelectedView] = useState('information');
  const [showEditModal, setShowEditModal] = useState(false);
  const [charity, setCharity] = useState();
  const charityResponse = useCharity();
  const {
    isLoading: charityIsLoading,
    data,
    isSuccess: isCharitySuccess,
  } = charityResponse || {};
  console.log('cr', charityResponse);
  useEffect(() => {
    if (isCharitySuccess) setCharity(charityResponse.data?.response.charity);
    return () => {};
  }, [charityResponse.data?.response.charity, isCharitySuccess]);
  return (
    <div className="min-h-screen overflow-hidden bg-background font-poppins">
      <DashboardHeader currentPage="charity" />
      <div className="flex p-8 desktop:flex-row phone:flex-col">
        <div id="charity-sidebar" className="w-1/6">
          <h2 className="font-medium text-xxl text-homeTextColor">
            My Charity
          </h2>
          <div className="mt-12">
            <NavButton
              text="Information"
              onClick={() => setSelectedView('information')}
              selected={selectedView === 'information'}
            />
            <br />
            <NavButton
              text="Campaigns"
              onClick={() => setSelectedView('campaigns')}
              selected={selectedView === 'campaigns'}
            />
          </div>
        </div>
        <div id="charity-content" className="desktop:w-4/5 phone:w-full">
          <EditCharityModal
            show={showEditModal}
            setShow={setShowEditModal}
            charity={
              charity || {
                name: '',
                address: '',
                category: '',
                ein: '',
                tags: [''],
                generalEmail: '',
                tagLine: '',
                mission: '',
              }
            }
            setCharity={setCharity}
          />
          <Banner className="flex flex-col gap-y-4 desktop:h-10 phone:h-72">
            <span className="font-medium desktop:text-faqText phone:text-base">
              Welcome to your Charity Portal
            </span>
            <span className="mt-4 desktop:text-einText phone:text-sm text-homeTextColor">
              Here’s where you’ll see the information that we already have about
              your charity. Some of it may be incomplete or inaccurate. This is
              where you can edit or add information to make your organization
              shine.
            </span>
            <span className="desktop:text-einText phone:text-sm text-homeTextColor">
              Attract donors by describing what you do and for whom.
            </span>
          </Banner>
          <br />
          <br />
          {selectedView === 'information' ? (
            <MyCharityInformation
              charity={data?.response.charity}
              charityIsLoading={charityIsLoading}
              setShowEditModal={setShowEditModal}
              totalRaised={data?.response?.totalRaised}
              donors={data?.response?.numberDonors}
            />
          ) : null}
          {selectedView === 'campaigns' ? <MyCharityCampaign /> : null}
        </div>
      </div>
    </div>
  );
};

export default MyCharity;
