import React from 'react';
import Spinner from './Spinner';

interface Props {
  loading?: boolean;
  open?: boolean;
  items?: Array<any>;
  onClick: Function;
}

const Dropdown: React.FunctionComponent<Props> = ({
  loading,
  open,
  items,
  onClick,
}) =>
  open ? (
    <>
      <div
        className="absolute left-0 w-full mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
      >
        {loading ? (
          <div className="flex justify-center py-2">
            <Spinner />
          </div>
        ) : (
          <div className="py-1" role="none">
            {items?.length ? (
              items.map((i) => (
                <div
                  key={i.id}
                  className="block px-4 py-2 text-sm text-gray-700 hover:cursor-pointer hover:bg-primary hover:text-gray-200"
                  role="menuitem"
                  id="menu-item-0"
                  onClick={() => onClick(i)}
                >
                  {i.name}
                </div>
              ))
            ) : (
              <span className="mx-4">No items found.</span>
            )}
          </div>
        )}
      </div>
    </>
  ) : (
    <></>
  );

export default Dropdown;
