import { validationMessages } from './../../utils/validations';
import * as Yup from 'yup';

import {
  lettersOnly,
  parseDateString,
  phoneRegExp,
} from 'src/utils/validations';
import { formatRelative, subYears } from 'date-fns';

export const initialValues = {
  firstName: '',
  lastName: '',
  jobTitle: '',
  dateOfBirth: '',
  phoneNumber: '',
  email: '',
  charity: '',
  password: '',
  confirmPassword: '',
};

const {
  requiredMessage,
  tooShortMessage,
  tooLongMessage,
  lettersOnlyMessage,
  phoneMessage,
  emailMessage,
  dateMessage,
  pwConfirmMessage,
  ageMessage,
} = validationMessages;

export const ClaimCharityFormSchema = Yup.object().shape({
  charity: Yup.string().required(requiredMessage),
  firstName: Yup.string()
    .min(2, tooShortMessage)
    .max(100, tooLongMessage)
    .matches(lettersOnly, lettersOnlyMessage)
    .required(requiredMessage),
  lastName: Yup.string()
    .min(2, tooShortMessage)
    .max(100, tooLongMessage)
    .matches(lettersOnly, lettersOnlyMessage)
    .required(requiredMessage),
  jobTitle: Yup.string()
    .min(2, tooShortMessage)
    .max(100, tooLongMessage)
    .required(requiredMessage),
  email: Yup.string().email(emailMessage).required(requiredMessage),
  phoneNumber: Yup.string()
    .min(10, tooShortMessage)
    .max(10, tooLongMessage)
    .matches(phoneRegExp, phoneMessage)
    .required(requiredMessage),
  dateOfBirth: Yup.date()
    .transform(parseDateString)
    .max(formatRelative(subYears(new Date(), 18), new Date()), ageMessage)
    .required(dateMessage),
  password: Yup.string()
    .required(requiredMessage)
    .test('passwordConfirmation', pwConfirmMessage, function (pw) {
      return this.parent.confirmPassword === pw;
    }),
  confirmPassword: Yup.string().test(
    'passwordConfirmation',
    pwConfirmMessage,
    function (pw) {
      return this.parent.password === pw;
    }
  ),
});
