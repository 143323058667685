import React from "react";
import classNames from "classnames";

interface NavButtonProps {
  children?: React.ReactNode;
  text: String;
  onClick: () => void;
  selected: boolean;
}

const NavButton: React.FunctionComponent<NavButtonProps> = ({
  children,
  text,
  onClick,
  selected,
}) => {
  return (
    <div
      className={classNames(
        "w-64 h-11 rounded-lg cursor-pointer",
        selected
          ? "bg-dash text-white hover:bg-gray-500"
          : "bg-transparent text-textSidebar hover:bg-dash hover:text-white"
      )}
      onClick={onClick}
    >
      <button className={classNames("text-nav font-medium pl-5 pt-2.5")}>
        {text}
        {children}
      </button>
    </div>
  );
};

export default NavButton;
