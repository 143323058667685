import React from 'react';
import { PhotographIcon, CameraIcon } from '@heroicons/react/outline';
import { Charity } from './MyCharity';
import {
  Button,
  Flex,
  Pill,
  SkeletonContainer,
  SkeletonDisplayText,
} from '@contentful/f36-components';
import { useComplianceData } from 'src/hooks';

interface MyCharityInformationProps {
  setShowEditModal: any;
  charity: Charity;
  charityIsLoading: boolean;
  totalRaised: number;
  donors: number;
}

const MyCharityInformation: React.FunctionComponent<
  MyCharityInformationProps
> = ({
  children,
  setShowEditModal,
  charity,
  charityIsLoading,
  totalRaised,
  donors,
}) => {
  const renderTags = () =>
    charity.tags.map((tag) => (
      <Pill className="mr-2 my-2" key={tag} label={tag} />
    ));

  const { data: complianceData, isLoading: complianceIsLoading } =
    useComplianceData();

  const renderName = () => {
    if (complianceIsLoading)
      return (
        <div className="flex flex-row h-5 space-x-3 mt-3">
          <SkeletonContainer children={<SkeletonDisplayText />} />
          <SkeletonContainer children={<SkeletonDisplayText />} />
        </div>
      );
    return `${complianceData?.firstName || ''} ${
      complianceData?.lastName || ''
    }`;
  };

  const renderPhone = () => {
    if (complianceIsLoading)
      return (
        <div className="flex flex-row h-5 space-x-3 mt-3">
          <SkeletonContainer children={<SkeletonDisplayText />} />
        </div>
      );
    return complianceData?.phoneNumber;
  };

  const renderWebsite = () => {
    if (complianceIsLoading)
      return (
        <div className="flex flex-row h-5 space-x-3 mt-3">
          <SkeletonContainer children={<SkeletonDisplayText />} />
        </div>
      );
    return complianceData?.website;
  };

  const renderEmail = () => {
    if (complianceIsLoading || charityIsLoading)
      return (
        <div className="flex flex-col h-5 space-x-3 mt-3">
          <SkeletonContainer children={<SkeletonDisplayText />} />
        </div>
      );

    return (
      <div className="flex flex-col">
        <div>{complianceData?.email}</div>
        <div>{charity?.generalEmail}</div>
      </div>
    );
  };

  const parseLocation = (address: any) => `${address?.city}, ${address?.state}`;

  return (
    <div className="bg-background flex desktop:flex-row phone:flex-col desktop:space-x-8">
      <div className="desktop:w-4/5 phone:w-full">
        <div className="flex flex-col">
          <div className="flex desktop:w-full phone:w-full h-52 bg-white shadow-div rounded-lg mb-4 items-center justify-center">
            {/* logo link for charity should go here */}
            <PhotographIcon className="w-6 h-6 text-gray-300" />
          </div>
          <div className="flex flex-row mb-4 cursor-pointer space-x-2">
            <CameraIcon className="w-7 h-7 text-primary" />
            <span
              className="underline pt-1 text-sm text-primary"
              onClick={() => alert('upload pic')}
            >
              Add an Image
            </span>
          </div>
          <div className="flex flex-col bg-white shadow-div rounded-lg p-8">
            <div className="flex flex-row space-x-2 bg-white">
              <div className="flex w-40 h-40 border-2 items-center justify-center">
                <PhotographIcon className="w-6 h-6 text-gray-300" />
              </div>
              <div className="flex flex-col justify-end">
                <CameraIcon className="w-7 h-7 text-primary" />
              </div>
              <div className="flex flex-col justify-end cursor-pointer text-primary">
                <span className="underline" onClick={() => alert('upload pic')}>
                  Add Your Logo
                </span>
              </div>
            </div>
            <div className="mt-4 flex flex-row justify-between place-items-center w-full">
              <span className="text-xl_2 font-bold text-homeTextColor">
                <Flex>{charity?.name}</Flex>
              </span>
              <Button
                variant="transparent"
                children="Edit"
                onClick={() => setShowEditModal(true)}
              />
            </div>
            <span className="text-base font-medium text-homeTextColor pt-1">
              <span>EIN#:{charity?.ein}</span>
              <br />
              {parseLocation(charity?.address)}
              <span className="flex flex-col">
                Total Raised: ${totalRaised}
              </span>
              Donors: {donors}
            </span>

            <hr className="pl-8 my-4" />
            <div className="flex flex-row">{charity?.tags && renderTags()}</div>
            <div className="flex flex-col mt-8">
              <span className="text-xl font-bold text-homeTextColor">
                Mission Statement
              </span>
              <span className="flex flex-wrap text-homeTextColor">
                {charity?.mission}
              </span>
            </div>
            <div className="flex flex-col mt-8">
              <span className="text-xl font-bold text-homeTextColor">
                Tag Line
              </span>
              <span className="flex flex-wrap text-homeTextColor">
                {charity?.tagLine}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="desktop:w-1/5 phone:w-full bg-background desktop:space-b-8 phone:space-y-8">
        <div className="flex flex-col shadow-contact rounded-lg bg-white ">
          <div className="flex flex-row">
            <span className="p-8 text-xl font-medium">Contact Info</span>
            <div
              className="flex flex-col justify-center cursor-pointer"
              onClick={() => alert('edit contact info')}
            >
              <span className="underline text-primary text-sm">Edit</span>
            </div>
          </div>
          <hr />
          <div className="flex flex-col p-8">
            <span className="text-sm">Admin Name</span>
            <span className="text-lg_2 font-medium">{renderName()}</span>
          </div>
          <div className="flex flex-col pl-8">
            <span className="text-sm">Phone</span>
            <span className="text-lg_2 font-medium">{renderPhone()}</span>
          </div>
          <div className="flex flex-col pl-8 pt-8">
            <span className="text-sm">Website</span>
            <span className="text-lg_2 font-medium">{renderWebsite()}</span>
          </div>
          <div className="flex flex-col pl-8 pt-8 pb-8">
            <span className="text-sm">Email</span>
            <span className="text-lg_2 font-medium">{renderEmail()}</span>
          </div>
        </div>
        <div className="flex flex-col shadow-contact rounded-lg mt-8 bg-white">
          <span className="p-8 text-xl font-medium">Charity Status</span>
          <hr />
          <div className="flex flex-row p-8">
            <label className="text-sm font-medium mr-4" htmlFor="toggle">
              Active
            </label>
            <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
              <input
                type="checkbox"
                name="toggle"
                id="toggle"
                className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                // defaultChecked={charity?.active}
              />
              <label
                htmlFor="toggle"
                className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
              ></label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyCharityInformation;
