import React from 'react';
import { FormControl, TextInput } from '@contentful/f36-components';
import { Field } from 'formik';

export interface InputProps {
  name: string;
  text: string;
  className?: string | undefined;
  type?: string | undefined;
  value?: string | undefined;
  autoComplete?: string | undefined;
  onChange?: Function | undefined;
  placeholder?: string | undefined;
  prependIconBtn?: any;
}

export const Input = ({
  name,
  text,
  type = '',
  value = '',
  className = '',
  placeholder = '',
  onChange = () => {},
  autoComplete = '',
  prependIconBtn,
}: InputProps) => (
  <div className={className || ''}>
    <Field name={name}>
      {({ field, meta }: { field: any; meta: any }) => (
        // To set the FormControl invalid state, check if there is an error related to this input
        <FormControl isInvalid={Boolean(meta.touched && meta.error)}>
          <FormControl.Label>{text}</FormControl.Label>
          <TextInput.Group>
            {prependIconBtn}
            <TextInput
              {...field}
              type={type}
              autoComplete={autoComplete}
              placeholder={placeholder}
              value={value || field.value}
              onChange={(e) => {
                field.onChange(e);
                onChange(e);
              }}
            />
          </TextInput.Group>
          {meta.touched && meta.error && (
            <FormControl.ValidationMessage>
              {meta.error}
            </FormControl.ValidationMessage>
          )}
        </FormControl>
      )}
    </Field>
  </div>
);

export default Input;
