import { Redirect, Route } from 'react-router-dom';
import { useAuthCookie } from 'src/hooks';

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const cookieData = useAuthCookie();
  return (
    <Route
      {...restOfProps}
      render={(props) => {
        return cookieData.status === 'loading' || cookieData.data?.auth ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
}

export default ProtectedRoute;
