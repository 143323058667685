import { useQuery } from 'react-query';
import { getAuthToken } from 'src/api/frontend/getAuthToken';
import { useComplianceData, useFlipAccountToHw } from 'src/hooks';

const getAuth = async (flipIt: any, updatedData: any) => {
  const res = (await getAuthToken()) as any;

  if (res.message === 'Invalid Givinga Auth Token') return;
  if (!res.userToken) await flipIt(updatedData);

  return res;
};

export function useAuthToken() {
  const { data: updatedData } = useComplianceData();
  const { mutate: flipIt } = useFlipAccountToHw();

  return useQuery('authToken', async () => getAuth(flipIt, updatedData));
}

export default useAuthToken;
