import { useState, useEffect } from 'react';
import { charitySearch } from '../../api/frontend/charitySearch';
import Dropdown from '../common/Dropdown';
import { SearchIcon } from '@heroicons/react/outline';
import { Input } from '../common/FormComponents';
import { IconButton } from '@contentful/f36-button';

interface Props {
  onSelect: Function;
  value?: { name: string } | null;
}

const CharityLookup: React.FunctionComponent<Props> = ({ value, onSelect }) => {
  const [query, setQuery] = useState('');
  const [displayed, setDisplayed] = useState(value?.name);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [charities, setCharities] = useState([]);

  useEffect(() => setIsOpen(!!query.length), [query]);
  useEffect(() => setDisplayed(query), [query]);
  useEffect(() => {
    if (value) setDisplayed(value.name);
  }, [value]);
  useEffect(() => {
    if (query.length > 2) {
      (async () => {
        setLoading(true);
        const { error, charities } = await charitySearch(query);

        if (!error && charities) setCharities(charities);
        setLoading(false);
      })();
    }
  }, [query]);

  const handleChange = (e: any) => {
    setQuery(e.target.value);
  };

  return (
    <div className="flex flex-col">
      <Input
        autoComplete="off"
        prependIconBtn={
          <IconButton
            variant="secondary"
            icon={<SearchIcon className="w-5 h-5" />}
            type="submit"
            aria-label="search"
          />
        }
        name="charity"
        value={displayed}
        text="Search for your charity"
        placeholder={'Search by EIN or Name'}
        onChange={handleChange}
      />

      <div className="relative z-50 border-1 border-gray-300">
        <Dropdown
          items={charities.map(({ id, name, ein }) => ({
            id,
            name: `${name} – ${ein}`,
          }))}
          open={isOpen}
          onClick={(i: { name: string }) => {
            onSelect(i);
            setIsOpen(false);
          }}
          {...{ loading }}
        />
      </div>
    </div>
  );
};

export default CharityLookup;
