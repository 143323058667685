import axios from '../../utils/axios';

export const getCharityDetails = async () => {
  const {
    data: {
      response: { id },
    },
  } = await axios.get(`/.netlify/functions/current-charity-id`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const { data } = await axios.get(
    `/.netlify/functions/current-charity-details?id=${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return { error: data.error, response: data.response };
};
