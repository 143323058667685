import React, { useState } from 'react';
import DashboardHeader from '../common/DashboardHeader';
import { PhoneIcon, MailIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

type FAQProps = {};

const getData = () => {
  const data = [
    {
      title: 'What is the Givinga Foundation Charity Portal?',
      answer:
        'The Charity Portal allows charities that have received donations made through Givinga’s philanthropic technology, Philantech®, to register for faster, safer delivery of donations with electronic payments and access the available donation transparency they deserve. A key pillar of improving the philanthropic experience for charities, the portal actively works to serve the final step in the donation process, by fostering a community of connection, transparency, and impact.',
    },
    {
      title: 'Who is the Givinga Foundation?',
      answer:
        'The Givinga Foundation is a 501(c)(3) public charity dedicated to making giving better. Our foundation offers donor advised funds with no minimum balance required, an open API platform, and connections to over 1.8M charities, allowing companies and individuals the opportunity to access our platform and find new and innovative ways to reward charitable giving.',
    },
    {
      title: 'Where are the donations coming from?',
      answer:
        'Donations come from individuals and companies that use our philanthropic technology, Philantech®. The Givinga Foundation works with a variety of marketplace partners to direct these funds to nonprofits and foundations like yours.',
    },
    {
      title: 'What do I need to register for the portal?',
      answer:
        'To register for Charity Portal you will need the following information: First Name, Last Name, Job Title, Email, (*Enter the email where you conduct charity business), Create Password. View the complete set-up guide to register for Charity Portal.',
    },
    {
      title: 'How do I sign up for electronic payments?',
      answer:
        'An “Electronic Payments” message is displayed each time a user signs in to the Charity Portal. Click on “Link a Transfer Method” and follow the prompts to sign up for electronic payments.',
    },
  ];
  return [...data];
};

const FAQ: React.FunctionComponent<FAQProps> = ({ children }) => {
  const [data] = useState(getData());

  const renderFAQ = (questions: Array<Record<string, string>>) => {
    return (
      <div className="flex flex-col w-5/6">
        {questions.map((question, index) => {
          return (
            <div className="flex flex-col mt-4 mb-6 space-y-4" key={index}>
              <div className="flex flex-row items-center p-4 bg-white rounded-lg shadow-div">
                <span className="w-5/6 text-faqText lg:w-5/6 sm:w-5/6">
                  {question.title}
                </span>
              </div>
              <div
                className={classNames(
                  'flex flex-col border rounded-lg shadow-md p-4 bg-white font-light'
                )}
              >
                {question.answer}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="min-h-screen overflow-hidden bg-background font-poppins">
      <DashboardHeader currentPage="faq" />
      <div className="flex p-16 desktop:flex-row phone:flex-col sm:p-8 desktop:space-x-3 phone:space-y-8">
        <div id="faq-sidebar" className="desktop:w-1/5 phone:w-full">
          <h2 className="font-semibold text-left text-xxl">
            Frequently Asked Questions
          </h2>
        </div>
        <div className="flex flex-col items-center h-auto desktop:w-3/5 phone:w-full">
          {/* <div className='relative h-10 text-gray-400 desktop:w-1/2 phone:w-full lg:w-3/5 sm:w-72 focus-within:text-gray-900'>
            <span className='absolute inset-y-0 left-0 flex items-center pl-2'>
              <button
                type='submit'
                className='p-1 focus:outline-none focus:shadow-outline'>
                <SearchIcon className='w-5 h-5' />
              </button>
            </span>
            <input
              name='faq-search'
              type='text'
              className='w-full py-2 pl-10 text-gray-600 placeholder-gray-300 border-gray-300 rounded-full text-loginText font-poppins focus:outline-none'
              placeholder='Type a question or keywords'
            />
          </div> */}
          {renderFAQ(data)}
        </div>
        <div className="flex flex-col bg-white desktop:w-80 phone:w-full shadow-div font-poppins h-60 lg:h-60 sm:h-36">
          <h2 className="pt-5 pb-6 pl-6 font-medium border text-contact bg-background border-greyBorderColor">
            Contact Us
          </h2>
          <div className="flex flex-row pt-8 text-sm pl-7">
            <a className="font-medium text-purpleText" href="tel:339-200-9064">
              <PhoneIcon className="w-6 h-6 text-purpleText" />
            </a>
            <a
              className="pl-4 font-medium text-purpleText"
              href="tel:339-200-9064"
            >
              (339) 200-9064
            </a>
          </div>
          <div className="flex flex-row pt-8 text-sm pl-7">
            <a
              className="font-medium text-purpleText"
              href="mailto:info@givingafoundation.org"
            >
              <MailIcon className="w-6 h-6 text-purpleText" />
            </a>
            <span className="pl-4 font-medium text-purpleText">
              <a href="mailto:info@givingafoundation.org">
                info@givingafoundation.org
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
