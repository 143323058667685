import React from "react";

type MyCharityCampaignProps = {};

const MyCharityCampaign: React.FunctionComponent<MyCharityCampaignProps> = ({
  children,
}) => {
  return <div className="shadow-md">My Charity Campaign</div>;
};

export default MyCharityCampaign;
