import axios from '../../utils/axios';

type Body = {
  email: string;
  password: string;
};

export const loginCharity = async (body: Body) => {
  const {
    data: { response, error },
  } = await axios.post(`/.netlify/functions/login`, body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return { error, response };
};
