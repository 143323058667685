import { useQuery } from 'react-query';
import { getCharityDetails } from 'src/api/frontend/getCharityDetails';

const getCharity = async () => {
  return await getCharityDetails();
};

export function useCharity() {
  return useQuery('charity', () => getCharity());
}

export default useCharity;
