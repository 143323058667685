import axios from '../../utils/axios';

export const getAttributionLetter = async (query: string) => {
  const { error, data } = await axios
    .get(`/.netlify/functions/get-attribution?transactionId=${query}`)
    .then((r: any) => r)
    .catch((e: any) => e);
  
  return { error, attribution: data };
};
