import { Formik } from 'formik';
import { Button, Flex, Notification, Form } from '@contentful/f36-components';
import { Input } from '../common/FormComponents';
import CharityLookup from './CharityLookup';
import { useState } from 'react';
import { useClaimCharity } from '../../hooks';
import { useHistory } from 'react-router';
import { ClaimCharityFormSchema, initialValues } from './ClaimCharitySchema';

interface ClaimCharityFormProps {
  setSuccess: Function;
}

export interface Charity {
  id: number;
  name: string;
}

export const CharityInfoForm: React.FunctionComponent<
  ClaimCharityFormProps
> = ({ setSuccess }) => {
  const [charity, setCharity] = useState();
  const claimCharityRes = useClaimCharity();
  const { mutate: claimCharity, status } = claimCharityRes;
  const { push } = useHistory();

  const onSubmit = (values: any) => async (e: any) => {
    const parsedData = { ...values, charity: charity };
    e.preventDefault();
    claimCharity(parsedData);
  };

  if (status === 'success' && claimCharityRes?.data?.error)
    Notification.error(claimCharityRes?.data?.error.message);
  if (status === 'success' && !claimCharityRes?.data?.error) setSuccess(true);

  return (
    <Formik
      onSubmit={onSubmit(() => {})}
      initialValues={initialValues}
      validationSchema={ClaimCharityFormSchema}
    >
      {({ values, isValid }) => {
        return (
          <div className="w-full flex flex-col items-center">
            <Form onSubmit={onSubmit(values)} className=" sm:w-608">
              <p className="w-full pt-5 font-semibold text-white rounded-t-lg text-loginHeader bg-primary pl-7 pb-7">
                Claim Your Charity
              </p>
              <div className="p-5 shadow-div">
                <div className="">
                  <CharityLookup value={charity} onSelect={setCharity} />
                </div>
                <hr className="border-gray-300 mb-5" />
                <div className="flex flex-row space-x-3">
                  <Input
                    name="firstName"
                    text="First Name"
                    className="w-full"
                  />
                  <Input name="lastName" text="Last Name" className="w-full" />
                </div>
                <div className="flex flex-row space-x-3">
                  <div className="flex w-full flex-col space-x-3">
                    <Input
                      name="phoneNumber"
                      text="Phone Number (format: 5551231234)"
                      className="w-full"
                    />
                  </div>
                  <Input
                    name="dateOfBirth"
                    type="date"
                    text="DOB"
                    className="w-full z-10"
                  />
                </div>
                <div className="flex flex-row space-x-3">
                  <Input name="jobTitle" text="Job Title" className="w-1/2" />
                  <Input name="email" text="Email" className="w-1/2" />
                </div>
                <Input
                  name="password"
                  type="password"
                  text="Create Password"
                  className="w-full"
                />
                <Input
                  name="confirmPassword"
                  type="password"
                  text="Password Confirm"
                  className="w-full"
                />

                <hr className="border-gray-300 mb-5" />
                <Flex justifyContent="flex-end">
                  <Button
                    className="text-primary"
                    variant="transparent"
                    onClick={() => push('/login')}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="bg-blue-900 w-44 text-white ml-3"
                    variant={'bg-primary' as 'primary'}
                    type="submit"
                    isDisabled={!isValid}
                  >
                    Register
                  </Button>
                </Flex>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default CharityInfoForm;
