import React, { useMemo } from 'react';
import { Transaction } from '../../lib';
import Table from '../common/Table';
import { format } from 'date-fns';
import { Button } from '@contentful/f36-components';

import {
  useTransactions,
  useComplianceData,
  useCurrentTransferMethod,
} from 'src/hooks';

type DashboardPaymentSettingsProps = {
  openTransferModal: any;
  paymentMethodType?: String | undefined;
};

const DashboardPaymentSettings: React.FunctionComponent<
  DashboardPaymentSettingsProps
> = ({ openTransferModal, paymentMethodType }) => {
  const { data: transactionsData, isLoading } = useTransactions();
  const { data: complianceData } = useComplianceData();
  const { currentTransferMethod } = useCurrentTransferMethod();
  const disbursements = transactionsData?.results?.filter(
    (x: Transaction) => Object.keys(x.disbursement).length !== 0
  );

  const getTotal = (arr: Transaction[], prop: keyof Transaction) => {
    return arr?.reduce((x: number, y: Transaction): number => {
      const value: any = y[prop];
      return x + value;
    }, 0);
  };

  const getAvailableTransferTotal = () => {
    if (isLoading) return null;
    const noDisbursement = transactionsData?.results?.filter(
      (x: Transaction) => Object.keys(x.disbursement).length === 0
    );
    const availableForDisbursement = getTotal(noDisbursement, 'amount');
    return availableForDisbursement?.toFixed(2);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'created',
        Cell: (props: any) => {
          const date = format(new Date(props.value), 'MMM. d, yyyy');
          return <span>{date}</span>;
        },
      },
      {
        Header: 'Status',
        accessor: 'disbursement.approvalStatus',
      },
      {
        Header: 'Transfer Method',
        accessor: 'disbursement.paymentMethod',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: (props: any) => {
          return <span>${props.value}</span>;
        },
      },
    ],
    []
  );

  const renderPaymentMethodType = () => {
    if (paymentMethodType === 'hyperwallet-paper')
      return (
        <span>
          Paper checks to: {complianceData?.firstName}{' '}
          {complianceData?.lastName}
        </span>
      );
    if (currentTransferMethod?.type === 'PAYPAL_ACCOUNT')
      return `Paypal: ${currentTransferMethod.email}`;
    if (currentTransferMethod?.type === 'BANK_ACCOUNT')
      return (
        <div>
          <span>Bank Account: </span> {currentTransferMethod.bankAccountId}
        </div>
      );
  };

  return (
    <div>
      <div className="flex flex-col  h-full desktop:space-y-8 phone:space-y-16">
        <div className="flex desktop:flex-row  tablet:flex-row phone:flex-col w-full  tablet:space-x-8 ">
          <div className="flex flex-col desktop:w-1/2 tablet:w-1/2 phone:w-full shadow-div bg-white p-4 rounded-lg">
            <span className="text-lg font-medium">Payment Details</span>
            <span className="text-sm font-normal leading-6">
              You have a total of{' '}
              <span className="text-lg text-dataTextColor">
                ${getAvailableTransferTotal()}
              </span>{' '}
              in donations awaiting transfer.
            </span>
          </div>
          <div className="flex flex-col desktop:w-1/2 tablet:w-1/2 phone:w-full shadow-div bg-white p-4 rounded-lg">
            <span className="text-lg font-medium">Payment Transfer Method</span>
            <br />
            <div className="flex flex-row">
              <div className="desktop:w-4/5 mt-1 tablet:w-full">
                <span className="w-full text-lg text-dataTextColor">
                  {renderPaymentMethodType()}
                </span>
              </div>

              <span className="text-purpleText desktop:text-sm tablet:text-sm phone:text-xs">
                <Button
                  variant="transparent"
                  onClick={() => openTransferModal(true)}
                >
                  {!currentTransferMethod ? 'Add Method' : 'Edit Method'}
                </Button>
              </span>
            </div>

            <hr className="border-purpleText " />
          </div>
        </div>
        {disbursements && disbursements.length > 0 && (
          <div className="flex flex-col bg-white border shadow-div border-greyBorderColor rounded-t-lg">
            <span className="text-lg p-6 font-medium">Payments</span>
            <div className="">
              <Table
                columns={columns}
                data={disbursements}
                search={false}
                pagination={false}
                details={false}
                edit={false}
                img={false}
                onActionClick={null}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardPaymentSettings;
