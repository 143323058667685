import React from "react";
import GivingaLogo from "../common/GivingaLogo";
import LoginHeader from "./LoginHeader";

type RecoverAccountProps = {};

// TODO: add validation on EIN number
// TODO: add recover account action

const RecoverAccount: React.FunctionComponent<RecoverAccountProps> = ({
  children,
}) => {
  const handleSubmit = (event: any) => {
    event.preventDefault();
    alert("submit");
    console.log(event.target.ein.value);
  };

  return (
    <div className="flex flex-col shadow-lg h-screen bg-background font-poppins">
      <LoginHeader />
      <div className="flex items-center justify-center object-contain h-auto m-auto w-full mt-16 mb-8">
        <GivingaLogo login />
      </div>
      <form onSubmit={handleSubmit}>
        <div
          id="recover-form"
          className="flex flex-col m-auto shadow-login bg-white rounded-md desktop:w-400 phone:w-72 -mt-4"
        >
          <p className="text-loginHeader bg-primary w-full text-white rounded-t-lg pt-5 pl-7 pb-7 font-semibold">
            Recover Account
          </p>
          <div className="p-4">
            <div className="flex flex-col p-4">
              <p className="text-einText">
                Enter the EIN# associated with your account.
              </p>
              <p className="text-sm mt-6 pb-1">EIN#</p>
              <input
                name="ein"
                type="text"
                className="w-full h-10 text-loginText border-gray-300 text-gray-600 focus:outline-none placeholder-gray-400"
                placeholder="Enter your EIN#"
              />
            </div>
            <button
              className="bg-primary text-white w-full h-11 rounded-lg text-login mt-40 mb-4 font-medium hover:opacity-80"
              type="submit"
              onClick={() => alert("Click")}
            >
              Recover my account
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RecoverAccount;
