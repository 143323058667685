import givingaBlueLogo from "../../img/givinga_logo.png";
import { useHistory } from "react-router-dom"; 

const DonationLookup = () => {
    let history = useHistory();

    const redirect = (transactionId: string) => {
        history.push(`/donation/${transactionId}`);
    };

    return (
        <div className="flex flex-col min-h-screen overflow-hidden bg-background font-poppins items-center">
            <div className="flex-row justify-center object-contain h-auto m-auto w-full mt-16 mb-8 py-8 items-center max-w-4xl bg-white shadow-lg space-y-12 px-8">
                <div className="flex flex-row space-x-2 items-end">
                <img className="h-16" alt="Givinga Logo" src={givingaBlueLogo}></img>
                </div>
                <div className="text-green-900 text-xxl text-center"><b>Givinga Foundation Donation Lookup</b></div>
                <div className="">Looking for information about a specific donation? Enter the invoice number below to view the attribution letter. The invoice number is located on the check you received from the Givinga Foundation.</div>

                <form className="group relative"
                    onSubmit={(e: React.SyntheticEvent) => {
                        e.preventDefault();
                        const target = e.target as typeof e.target & {
                            invoice: { value: string };
                        };
                        const invoice = target.invoice.value;
                        redirect(invoice)
                    }}
                >
                    <svg width="20" height="20" fill="currentColor" className="absolute left-3 top-1/2 -mt-2.5 text-gray-400 pointer-events-none group-focus-within:text-blue-500" aria-hidden="true">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" />
                    </svg>
                    <div className="flex flex-row space-x-2">
                        <input className="focus:ring-2 focus:ring-blue-500 focus:outline-none w-full text-sm leading-6 text-gray-900 placeholder-gray-400 rounded-md py-2 pl-10 ring-1 ring-gray-200 shadow-sm" type="text" name="invoice" aria-label="Search by invoice number..." placeholder="Search by invoice number..."></input>
                        <button className="rounded-full bg-green-900 align-middle w-36 text-white">Search</button>
                    </div>
                </form>

                <div className="flex flex-row flex-items-stretch align-baseline">
                    <div className="flex flex-col w-1/2">
                        <div className="text-md underline">Still have questions?</div>
                        <div className="">Contact support@givingafoundation.org</div>
                    </div>
                </div>

                <div className="flex flex-row items-stretch text-xs text-center">
                <div className="w-1/4">GIVINGA FOUNDATION</div>
                <div className="w-1/4">396 WASHINGTON ST. SUITE 307</div>
                <div className="w-1/4">WELLESLEY, MA 02481</div>
                <div className="w-1/4">(339) 200-9064</div>
                </div>
            </div>
        </div>
    );
};

export default DonationLookup;
