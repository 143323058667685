import React from 'react';
import { DownloadIcon } from '@heroicons/react/outline';

interface DownloadCSVButtonProps {
  onClick?: () => void;
  children?: React.ReactNode;
}

// TODO: Implement onClick for Download CSV.
// TODO: Will need to talk to Jieren about downloading CSVs
const DownloadCSVButton: React.FunctionComponent<DownloadCSVButtonProps> = (
  props
) => {
  return (
    <button className='inline-flex items-center justify-center px-4 py-2 bg-transparent border rounded-lg border-primary h-9 desktop:w-48 phone:w-full tablet:w-48'>
      <span className='text-sm font-bold text-primary'>Download CSV</span>
      <div className='pl-2'>
        <DownloadIcon className='w-5 h-5 text-primary' />
      </div>
    </button>
  );
};

export default DownloadCSVButton;
