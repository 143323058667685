import { useState, useEffect } from 'react';
import { TransferMethod } from '../lib';
import { useAuthToken, useCharitySettings } from '.';

const emptyTransferMethod: TransferMethod = {
  createdOn: '',
  token: '',
  type: '',
  email: '',
  bankAccountId: '',
};

export function useCurrentTransferMethod() {
  const [currentTransferMethod, setCurrentTransferMethod] =
    useState(emptyTransferMethod);
  const [isLoading, setIsLoading] = useState(true);
  const { data, status } = useAuthToken();
  const { data: charitySettings, status: charitySettingsStatus } =
    useCharitySettings();

  useEffect(() => {
    setIsLoading(true);

    const filteredTransferMethod = data?.transferMethods?.filter(
      (tm: TransferMethod) => tm?.token === charitySettings?.trmToken
    );
    if (status !== 'success' || charitySettingsStatus !== 'success')
      return setIsLoading(false);

    if (filteredTransferMethod)
      setCurrentTransferMethod(filteredTransferMethod[0]);
    setIsLoading(false);
    return () => {
      setIsLoading(false);
    };
  }, [
    charitySettings?.trmToken,
    charitySettingsStatus,
    data?.transferMethods,
    status,
  ]);

  return {
    currentTransferMethod,
    setCurrentTransferMethod,
    isLoading,
    setIsLoading,
  };
}

export default useCurrentTransferMethod;
