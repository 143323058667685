import ClaimCharitySuccess from './ClaimCharitySuccess';
import React, { useState } from 'react';
import GivingaLogo from '../common/GivingaLogo';
import LoginHeader from './LoginHeader';
import ClaimCharityForm from './ClaimCharityForm';

type ClaimCharityProps = {};

const ClaimCharity: React.FunctionComponent<ClaimCharityProps> = () => {
  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  return (
    <div className="flex flex-col min-h-screen shadow-lg bg-white font-poppins">
      <LoginHeader />
      <div className="flex items-center justify-center object-contain mt-16 mb-8">
        <GivingaLogo login />
      </div>
      {registrationSuccess ? (
        <ClaimCharitySuccess />
      ) : (
        <ClaimCharityForm setSuccess={setRegistrationSuccess} />
      )}
    </div>
  );
};

export default ClaimCharity;
