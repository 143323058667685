import React from 'react';
import classnames from 'classnames';
import { LogoutIcon } from '@heroicons/react/outline';
import GivingaLogo from './GivingaLogo';

interface DashboardHeaderProps {
  user?: Record<string, unknown>;
  onLogout?: (event: any) => void;
  children?: React.ReactNode;
  currentPage?: string;
}

const DashboardHeader: React.FunctionComponent<DashboardHeaderProps> = ({
  user,
  onLogout,
  currentPage = 'dashboard',
}: DashboardHeaderProps) => {
  return (
    <nav className="bg-primary font-poppins">
      <div className="w-full sm:px-6 lg:px-6">
        <div className="flex items-center  h-24">
          <div className="flex sm:items-stretch sm:justify-start">
            <div className="flex tablet:pl-0 phone:pl-0">
              <div className="desktop:h-10">
                <GivingaLogo />
              </div>
            </div>
          </div>
          <div className="flex flex-row sm:block sm:ml-6">
            <div className="flex space-x-10">
              <a
                href="/"
                className={classnames(
                  'text-white hover:underline underline-offset-large hover:text-white px-3 py-2 rounded-md text-upperNav font-medium ',
                  currentPage === 'dashboard' ? 'underline' : 'opacity-60'
                )}
                aria-current="page"
              >
                Home
              </a>

              {/* <a
                href="/charity"
                // className="px-3 py-2 text-lg font-medium text-gray-300 rounded-md hover:underline underline-offset-large hover:text-white"
                className={classnames(
                  'text-white hover:underline underline-offset-large hover:text-white px-3 py-2 rounded-md text-upperNav font-medium ',
                  currentPage === 'charity' ? 'underline' : 'opacity-60'
                )}
              >
                My Charity
              </a> */}

              {/* <a
                href="/team"
                className={classnames(
                  'text-white hover:underline underline-offset-large hover:text-white px-3 py-2 rounded-md text-upperNav font-medium ',
                  currentPage === 'team' ? 'underline' : 'opacity-60'
                )}
              >
                My Team
              </a> */}

              <a
                href="/faq"
                className={classnames(
                  'text-white hover:underline underline-offset-large hover:text-white px-3 py-2 rounded-md text-upperNav font-medium ',
                  currentPage === 'faq' ? 'underline' : 'opacity-60'
                )}
              >
                FAQ
              </a>
            </div>
          </div>
          <div
            className="flex flex-row ml-auto cursor-pointer"
            onClick={onLogout}
          >
            <span className="ml-8 text-white text-upperNav font-poppins">
              Logout
            </span>
            <LogoutIcon className="w-5 h-5 mt-1 ml-2 text-white" />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default DashboardHeader;
