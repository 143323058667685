import axios from "../../utils/axios";

type Response = {
  config: Record<string, string>;
  data: {
    error: Record<string, unknown>;
    response: Record<string, unknown>;
  };
};

export const getUsersInTeam = async () => {
  const data: Response = await axios
    .get("/.netlify/functions/users")
    .then((r: any) => r)
    .catch((e: any) => e);

  if (data.data.error !== null) {
    return data?.data?.error;
  }

  return data.data.response;
};
