import { FormControl, Pill, TextInput } from '@contentful/f36-components';
import { Field } from 'formik';

interface TagInputProps {
  name: String;
  text: String;
  tags: string[];
  removeTag: any;
  setValues: any;
  values: any;
  onChangeTags: any;
}

export const TagInput = ({
  name,
  text,
  tags,
  onChangeTags,
  removeTag,
  setValues,
  values,
}: TagInputProps) => {
  const renderTags = () =>
    tags?.map((tag) => (
      <Pill
        className="mr-3 mb-3 capitalize"
        key={tag}
        label={tag}
        onClose={() => removeTag(tag, setValues, values)}
      />
    ));

  return (
    <>
      <Field name={name}>
        {({ field, meta }: { field: any; meta: any }) => (
          <FormControl isInvalid={Boolean(meta.touched && meta.error)}>
            <FormControl.Label>{text}</FormControl.Label>
            <TextInput {...field} onChange={onChangeTags} />
            {meta.touched && meta.error && (
              <FormControl.ValidationMessage>
                {meta.error}
              </FormControl.ValidationMessage>
            )}
          </FormControl>
        )}
      </Field>
      {renderTags()}
    </>
  );
};

export default TagInput;
