// uat is the only one that works as of right now, maybe after we go live
export const useHwEnv = () => {
  if (
    process?.env.NODE_ENV === 'development' ||
    window.location.hostname.includes('sandbox')
  )
    return 'uat-';
  if (process?.env.NODE_ENV === 'production') return '';

  return 'uat-';
};

export default useHwEnv;
