import axios from '../../utils/axios';

type Body = {
  charity?: { id: number } | null;
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  password: string;
};

export const claimCharity = async (body: Body) => {
  const id = body.charity?.id;
  delete body.charity;

  const response = await axios.post(
    `/.netlify/functions/claim-charity?id=${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return { error: response.data.error, response: response.data.response };
};
