import { useMutation, useQueryClient } from 'react-query';
import httpService from 'src/services/src/lib/http-service';

const constructEndpoint = (endpoint: string) =>
  `.netlify/functions/${endpoint}`;

const mutateCharitySettings = async (newCharitySettings: any) => {
  const res = await httpService.put(
    constructEndpoint('put-current-charity-settings'),
    JSON.stringify({ trmToken: newCharitySettings.token })
  );
  return res;
};

export function useMutateCharitySettings() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (newCharitySettings: any) =>
      mutateCharitySettings(newCharitySettings),
    mutationKey: 'mutateCharitySettings',
    onSuccess: () => queryClient.refetchQueries(['charitySettings']),
  });
}

export default useMutateCharitySettings;
