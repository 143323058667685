import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { Notification } from '@contentful/f36-components';
import { logout } from 'src/api/frontend/logout';

const logoutCharity = async (history: any, queryClient: any) => {
  await logout();
  history.push('/login');
  await queryClient.removeQueries();
  Notification.success('Logged Out', { duration: 1000 });
};

export function useLogout() {
  const history = useHistory();
  const queryClient = useQueryClient();

  return useMutation(() => logoutCharity(history, queryClient));
}

export default useLogout;
