import { useEffect } from 'react';
import { useState } from 'react';
import { useCharity, useComplianceData } from '..';

export type ReqBody = {
  profileType: string;
  clientUserId: string;
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: string;
  addressLine1: string;
  city: string;
  country: string;
  stateProvince: string;
  postalCode: string;
};

export const useParseHwData = () => {
  const { data: charityData } = useCharity();
  const { data: complianceData, isLoading } = useComplianceData();

  const {
    firstName,
    lastName,
    email,
    dateOfBirth,
    address1,
    phoneNumber,
    city,
    stateProvince,
    postalCode,
    programToken,
  } = complianceData || {};
  const { id } = charityData?.response.charity || {};

  const [reqBody, setReqBody] = useState({});
  useEffect(() => {
    if (isLoading) return;
    setReqBody({
      profileType: '',
      email,
      clientUserId: id,
      firstName,
      lastName,
      dateOfBirth,
      addressLine1: address1,
      city,
      country: 'US',
      stateProvince,
      postalCode,
      phoneNumber,
      programToken: programToken || '',
    } as ReqBody);
  }, [
    address1,
    city,
    dateOfBirth,
    email,
    phoneNumber,
    firstName,
    id,
    isLoading,
    lastName,
    postalCode,
    programToken,
    stateProvince,
  ]);

  return { reqBody, setReqBody };
};

export default useParseHwData;
