import { flipAccountToHyperwallet } from 'src/api/frontend/flipToHyperwallet';
import { useMutation, useQueryClient } from 'react-query';
import { useComplianceData } from 'src/hooks';

const flipIt = async (updatedData: any) => {
  return await flipAccountToHyperwallet(updatedData);
};

export function useFlipAccountToHw() {
  const queryClient = useQueryClient();
  const { data: updatedData } = useComplianceData();
  return useMutation(() => flipIt(updatedData), {
    onSuccess: (res) => queryClient.refetchQueries(['authToken']),
  });
}

export default useFlipAccountToHw;
