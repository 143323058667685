import { Switch, Route } from 'react-router-dom';
import CharityDashboard from '../components/Dashboard/CharityDashboard';
import FAQ from '../components/FAQ/FAQ';
import CharityLogin from '../components/Login/CharityLogin';
import ClaimCharity from '../components/Login/ClaimCharity';
import RecoverAccount from '../components/Login/RecoverAccount';
import ResetPassword from '../components/Login/ResetPassword';
import MyCharity from '../components/My Charity/MyCharity';
import MyTeam from '../components/My Team/MyTeam';
import AttributionLetter from '../components/Attribution Letter/AttributionLetter';
import DonationLookup from '../components/Attribution Letter/DonationLookup';
import ProtectedRoute from './ProtectedRoute';

function Routes() {
  return (
    <Switch>
      <Route path="/login" exact component={CharityLogin}></Route>
      <Route path="/recover" exact component={RecoverAccount}></Route>
      <Route path="/claim" exact component={ClaimCharity}></Route>
      <Route path="/reset" exact component={ResetPassword}></Route>
      <Route path="/faq" exact component={FAQ}></Route>
      <Route
        path="/donation/:transactionId"
        exact
        component={AttributionLetter}
      />
      <Route path="/donation-lookup" exact component={DonationLookup} />
      <ProtectedRoute path="/" exact component={CharityDashboard} />
      <ProtectedRoute path="/charity" exact component={MyCharity} />
      <ProtectedRoute path="/team" exact component={MyTeam} />
    </Switch>
  );
}

export default Routes;
