import { parse, isDate } from 'date-fns';

export const lettersOnly = /^[aA-zZ\s]+$/;

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export function parseDateString(originalValue: string) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'yyyy-MM-dd', new Date());
  return parsedDate;
}

const pwConfirmMessage = 'Password and password confirm must match.';
const emailMessage = 'Password and password confirm must match.';
const requiredMessage = 'Required.';
const tooShortMessage = 'Too Short.';
const tooLongMessage = 'Too long.';
const lettersOnlyMessage = 'Too long.';
const phoneMessage = 'Must be a valid phone number.';
const dateMessage = 'Must be a valid date.';
const ageMessage = 'Must be 18 years old.';

export const validationMessages = {
  ageMessage,
  pwConfirmMessage,
  emailMessage,
  requiredMessage,
  tooShortMessage,
  tooLongMessage,
  lettersOnlyMessage,
  phoneMessage,
  dateMessage,
};
