import { useQuery } from 'react-query';
import { getTransactions } from 'src/api/frontend/getTransactions';

const getTransactionsData = async () => {
  const response = await getTransactions();
  const {
    transactions: { data: transactionsData },
  } = response;

  return transactionsData;
};

export function useTransactions() {
  return useQuery('transactions', () => getTransactionsData());
}

export default useTransactions;
