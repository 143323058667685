import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class TransferMethodDropIn extends Component {
  static propTypes = {
    userToken: PropTypes.string,
    environment: PropTypes.oneOf(['sandbox', 'uat-', 'production']),
    getAuthenticationToken: PropTypes.func,
    complianceData: PropTypes.any,
    configurationTypes: PropTypes.any,
    onComplete: PropTypes.func,
    onError: PropTypes.func,
    template: PropTypes.string,
  };

  state = {
    sdkIsReady: false,
    widgetIsReady: false,
    isError: false,
  };

  componentDidMount() {
    this._addPaypalSdk(this.setState);
  }

  render() {
    if (this.state.sdkIsReady) {
      window.HWWidgets.initialize(this._getAuthenticationToken);
    }

    return (
      <div style={{ padding: 20 }}>
        {this.state.widgetIsReady && this.state.sdkIsReady
          ? null
          : this.state.isError
          ? 'error occurred'
          : '...loading'}
        <div id="TransferMethodUI" />
      </div>
    );
  }
  _getAuthenticationToken = (callback: any) => {
    const { getAuthenticationToken } = this.props as any;
    getAuthenticationToken(callback);
  };

  _addPaypalSdk = (setState: Function) => {
    const {
      userToken,
      environment,
      onComplete,
      template,
      onError,
      complianceData,
      configurationTypes,
    } = this.props as any;

    const doDisplay = () => {
      // console.log('Ready');
      // this.setState({ widgetIsReady: true });
    };

    const doLoad = (isLoading: any) => {
      // console.log('Loading', isLoading);
      this.setState({ sdkIsReady: !isLoading, widgetIsReady: !isLoading });
    };

    const doBeforeSubmit = (state: any) => {
      // console.log('About to submit', state);
    };

    const doSubmit = (state: any) => {
      // console.log('Submitting', state);
    };

    const doComplete = (trmObject: any, completionResult: any) => {
      // console.log('Complete', completionResult);
      onComplete(trmObject, completionResult);
    };

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://${environment}api.paylution.com/rest/widgets/transfer-methods/${userToken}/en.min.js`;
    script.async = true;
    script.onload = () => {
      this.setState({ sdkIsReady: true });
      const el = document.getElementById('TransferMethodUI');

      window.HWWidgets.transferMethods
        .configure({
          template: template || 'plain',
          el,
          transferMethodConfiguration: {
            country: 'US',
            currency: 'USD',
            profileType: configurationTypes.profileType,
            type: configurationTypes.type,
          },
          data: complianceData.reqBody,
          skipProfileFields: true,
          onLoading: (isLoading: any) => doLoad(isLoading),
          onBeforeSubmit: (state: any) => doBeforeSubmit(state),
          onSubmit: (state: any) => doSubmit(state),
          onComplete: (trmObject: any, completionResult: any) =>
            doComplete(trmObject, completionResult),
        })
        .display(doDisplay);
    };
    script.onerror = (e: any) => {
      this.setState({ isError: true });
      onError(e);
    };

    document.body.appendChild(script);
  };
}

export default TransferMethodDropIn;
