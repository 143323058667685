import httpService from 'src/services/src/lib/http-service';
import { useQuery } from 'react-query';
import { useUser } from '.';

export const getPMTs = async (clientUserId: string) => {
  const { data } = await httpService.get(
    `/.netlify/functions/get-payments?clientPaymentId=${clientUserId}`
  );
  return data;
};

export function usePayments() {
  const { data: userData, isSuccess } = useUser();

  return useQuery(
    'payments',
    () => getPMTs(userData?.data?.clientUserId as string),
    {
      enabled: isSuccess,
    }
  );
}

export default usePayments;
